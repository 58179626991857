import { compareObjects, verifyValidation } from '../../helpers';
import { SurveyType } from '../../models/ApiSurvey.type';

export const createObjectFromFE = (e) => ({
    customerAttention: e.customer?.fullName,
    customerEmail: e.customer?.email,
    customerTelephone: e.customer?.telephone,
    customerAddress: e.customer?.address,
    siteName: e.site?.name,
    mineOwner: e.site?.mineOwner,
    location: e.site?.location,
    startDate: e.site?.startDate,
    timeZone: e.site?.timeZone,
    surveyDate: e.site?.surveyDate,
    projectCoordinatorName: e.contact?.projectCoordinator?.name,
    projectCoordinatorEmail: e.contact?.projectCoordinator?.email,
    projectCoordinatorTelephone: e.contact?.projectCoordinator?.telephone,
    mineITName: e.contact?.mineIT?.name,
    mineITEmail: e.contact?.mineIT?.email,
    mineITTelephone: e.contact?.mineIT?.telephone,
    mineDispatchName: e.contact?.mineDispatchControl?.name,
    mineDispatchEmail: e.contact?.mineDispatchControl?.email,
    mineDispatchTelephone: e.contact?.mineDispatchControl?.telephone,
    tyreProviderName: e.contact?.tyreProvider?.name,
    tyreProviderEmail: e.contact?.tyreProvider?.email,
    tyreProviderTelephone: e.contact?.tyreProvider?.telephone,
    wifiAvailable: typeof e.wifi?.available !== 'undefined' ? e.wifi.available ? 'yes' : 'no' : undefined,
    wifiInternet: typeof e.wifi?.internet !== 'undefined' ? e.wifi.internet ? 'yes' : 'no' : undefined,
    wifiFirewall: typeof e.wifi?.firewall !== 'undefined' ? e.wifi.firewall ? 'yes' : 'no' : undefined,
    wifiRouter: typeof e.wifi?.router !== 'undefined' ? e.wifi.router ? 'yes' : 'no' : undefined,
    wifiEthernet: typeof e.wifi?.ethernet !== 'undefined' ? e.wifi.ethernet ? 'yes' : 'no' : undefined,
    cellularAvailable: typeof e.cellularNetwork?.available !== 'undefined'
        ? e.cellularNetwork.available ? 'yes' : 'no' : undefined,
    cellularProvider: e.cellularNetwork?.provider,
    cellularRestrictions: typeof e.cellularNetwork?.restrictions !== 'undefined'
        ? e.cellularNetwork.restrictions ? 'yes' : 'no'
        : undefined,
    dispatchSystem: e.dispatchSystem?.name,
    dispatchLink: typeof e.dispatchSystem?.link !== 'undefined' ? e.dispatchSystem.link ? 'yes' : 'no' : undefined,
    tmsSystem: e.tmsSystem,
    networkComment: e.networkComment,
    vehicles: e.fleet?.vehicles,
    totalQuantity: e.fleet?.totalQuantity,
    bridgestone: e.tyres?.bridgestone,
    michelin: e.tyres?.michelin,
    goodyear: e.tyres?.goodyear,
    otherBranch: e.tyres?.otherBranch,
    tyreAdditive: e.tyres?.tyreAdditive || e.tyres?.tyreAdditiveOther,
    fullName: e.personnelInformation?.fullName,
    jobTitle: e.personnelInformation?.jobTitle,
    company: e.personnelInformation?.company,
    emailPersonal: e.personnelInformation?.email,
    shiftName: e.shift?.name,
    shiftStart: e.shift?.start,
    shiftEnd: e.shift?.end,
    shiftChangeStartDay: e.shift?.changeStartDay,
    shiftStartTime: e.shift?.startTime,
    shiftEndTime: e.shift?.endTime,
    lunchtimeDayStart: e.shift?.lunchTimeDayStart,
    lunchtimeDayEnd: e.shift?.lunchTimeDayEnd,
    lunchtimeNightStart: e.shift?.lunchTimeNightStart,
    lunchtimeNightEnd: e.shift?.lunchTimeNightEnd,
    language: e.generalInfo?.language,
    schemaName: e.generalInfo?.schemaName,
    altitude: e.unitData?.altitude,
    pressureUnit: e.unitData?.pressure?.unit,
    lowPressureLimitLevel1: e.unitData?.pressure?.lowPressureLimitLevels?.level1,
    lowPressureLimitLevel2: e.unitData?.pressure?.lowPressureLimitLevels?.level2,
    lowPressureLimitLevel3: e.unitData?.pressure?.lowPressureLimitLevels?.level3,
    highPressureLimitLevel1: e.unitData?.pressure?.highPressureLimitLevels?.level1,
    highPressureLimitLevel2: e.unitData?.pressure?.highPressureLimitLevels?.level2,
    highPressureLimitLevel3: e.unitData?.pressure?.highPressureLimitLevels?.level3,
    temperatureUnit: e.unitData?.temperature?.unit,
    temperatureUnitLevel1: e.unitData?.temperature?.level1,
    temperatureUnitLevel2: e.unitData?.temperature?.level2,
    temperatureUnitLevel3: e.unitData?.temperature?.level3,
    temperatureLimits: e.unitData?.temperature?.temperatureLimits,
    speedUnit: e.unitData?.speed?.unit,
    maxSpeedLimit: e.unitData?.speed?.maxSpeedLimit
});

export const createObjectFromBE = (e) => ({
    customer: {
        fullName: e.customerAttention,
        email: e.customerEmail,
        telephone: e.customerTelephone,
        address: e.customerAddress
    },
    site: {
        name: e.siteName,
        mineOwner: e.mineOwner,
        location: e.location,
        startDate: e.startDate,
        timeZone: e.timeZone,
        surveyDate: e.surveyDate
    },
    contact: {
        projectCoordinator: {
            name: e.projectCoordinatorName,
            email: e.projectCoordinatorEmail,
            telephone: e.projectCoordinatorTelephone
        },
        mineIT: {
            name: e.mineITName,
            email: e.mineITEmail,
            telephone: e.mineITTelephone
        },
        mineDispatchControl: {
            name: e.mineDispatchName,
            email: e.mineDispatchEmail,
            telephone: e.mineDispatchTelephone
        },
        tyreProvider: {
            name: e.tyreProviderName,
            email: e.tyreProviderEmail,
            telephone: e.tyreProviderTelephone
        }
    },
    wifi: {
        available: e.wifiAvailable ? e.wifiAvailable === 'yes' : undefined,
        internet: e.wifiInternet ? e.wifiInternet === 'yes' : undefined,
        firewall: e.wifiFirewall ? e.wifiFirewall === 'yes' : undefined,
        router: e.wifiRouter ? e.wifiRouter === 'yes' : undefined,
        ethernet: e.wifiEthernet ? e.wifiEthernet === 'yes' : undefined
    },
    cellularNetwork: {
        available: e.wifiAvailable ? e.wifiAvailable === 'yes' : undefined,
        provider: e.cellularProvider,
        restrictions: e.cellularRestrictions ? e.cellularRestrictions === 'yes' : undefined
    },
    dispatchSystem: {
        name: e.dispatchSystem,
        link: e.dispatchLink ? e.dispatchLink === 'yes' : undefined
    },
    tmsSystem: e.tmsSystem,
    networkComment: e.networkComment,
    fleet: {
        vehicles: e.vehicles,
        totalQuantity: e.totalQuantity
    },
    tyres: {
        bridgestone: e.bridgestone,
        michelin: e.michelin,
        goodyear: e.goodyear,
        otherBranch: e.otherBranch,
        tyreAdditive: e.tyreAdditive === 'other' ? e.tyreAdditiveOther : e.tyreAdditive
    },
    personnelInformation: {
        fullName: e.fullName,
        jobTitle: e.jobTitle,
        company: e.company,
        email: e.emailPersonal
    },
    shift: {
        name: e.shiftName,
        start: e.shiftStart,
        end: e.shiftEnd,
        changeStartDay: e.shiftChangeStartDay,
        startTime: e.shiftStartTime,
        endTime: e.shiftEndTime,
        lunchTimeDayStart: e.lunchtimeDayStart,
        lunchTimeDayEnd: e.lunchtimeDayEnd,
        lunchTimeNightStart: e.lunchtimeNightStart,
        lunchTimeNightEnd: e.lunchtimeNightEnd
    },
    generalInfo: {
        language: e.language === 'other' ? e.languageOther : e.language,
        schemaName: e.schemaName
    },
    unitData: {
        altitude: e.altitude,
        pressure: {
            unit: e.pressureUnit,
            lowPressureLimitLevels: {
                level1: e.lowPressureLimitLevel1,
                level2: e.lowPressureLimitLevel2,
                level3: e.lowPressureLimitLevel3
            },
            highPressureLimitLevels: {
                level1: e.highPressureLimitLevel1,
                level2: e.highPressureLimitLevel2,
                level3: e.highPressureLimitLevel3
            }
        },
        temperature: {
            unit: e.temperatureUnit,
            level1: e.temperatureUnitLevel1,
            level2: e.temperatureUnitLevel2,
            level3: e.temperatureUnitLevel3,
            temperatureLimits: e.temperatureLimits
        },
        speed: {
            unit: e.speedUnit,
            maxSpeedLimit: e.maxSpeedLimit
        }
    }
});

export const checkStepValidation = (steps): boolean => {
    for (const stepKey in steps) {
        if (Object.prototype.hasOwnProperty.call(steps, stepKey)) {
            if (steps[stepKey].validStep === false) {
                return true;
            }
        }
    }
    return false;
};

export const generateValidateUpdate = (dataParseC, surveyFieldValidation) => {
    const objectValidOne = compareObjects(surveyFieldValidation.stepOne.fileds, dataParseC) as any;
    const objectValidTwo = compareObjects(surveyFieldValidation.stepTwo.fileds, dataParseC) as any;
    const objectValidThree = compareObjects(surveyFieldValidation.stepThree.fileds, dataParseC) as any;
    const objectValidFour = compareObjects(surveyFieldValidation.stepFour.fileds, dataParseC) as any;
    const objectValidFive = compareObjects(surveyFieldValidation.stepFive.fileds, dataParseC) as any;

    const ValidationsUpdate = {
        stepOne: {
            validStep: verifyValidation(objectValidOne),
            fileds: { ...surveyFieldValidation.stepOne.fileds, ...objectValidOne }
        },
        stepTwo: {
            validStep: verifyValidation(objectValidTwo),
            fileds: { ...surveyFieldValidation.stepTwo.fileds, ...objectValidTwo }
        },
        stepThree: {
            validStep: verifyValidation(objectValidThree),
            fileds: { ...surveyFieldValidation.stepThree.fileds, ...objectValidThree }
        },
        stepFour: {
            validStep: verifyValidation(objectValidFour),
            fileds: { ...surveyFieldValidation.stepFour.fileds, ...objectValidFour }
        },
        stepFive: {
            validStep: verifyValidation(objectValidFive),
            fileds: { ...surveyFieldValidation.stepFive.fileds, ...objectValidFive }
        }
    };

    return ValidationsUpdate;
};

export const showActionSubmit = (surveyData: SurveyType | null) => {
    return !surveyData?.lockedForEditUser && surveyData?.status !== 'accepted';
};

export const showActionApprove = (surveyData: SurveyType | null) => {
    return surveyData?.status === 'in_acceptance';
};

export const showActionSave = (surveyData: SurveyType | null, publicMode: boolean) => {
    return  !surveyData?.lockedForEditUser && publicMode || !publicMode;
};

export const showActionReturnProgress = (surveyData: SurveyType | null) => {
    return surveyData?.status === 'in_acceptance' || surveyData?.status === 'accepted';
};

export const findMatchingValue = (inputString: string, optionsSelect: {
    value: string;
    label: string;
}[]): string | undefined => {
    const matchingOption = optionsSelect.find(option => option.value === inputString);
    return matchingOption ? matchingOption.value : undefined;
};