import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { SiteSurveyViewProps } from './SiteSurvey.type';
import { SiteSurveyContainer, SiteSurveyLoadingContainer } from './SiteSurvey.style';
import StepOne from './StepOne/StepOne';
import { Loading } from '../../helpers/Loading';
import StepTwo from './StepTwo/StepTwo';
import { SurveyFieldRequiresState, SurveyStepState } from '../../states/component/Survey';
import { useRecoilValue } from 'recoil';
import StepThree from './StepThree/StepThree';
import StepFour from './StepFour/StepFour';
import StepFive from './StepFive/StepFive';
import { Box, Dialog, DialogContent, StepButton, Typography } from '@mui/material';
import { ButtonCloseDialog, ButtonFab, UiDialogTitle } from '../Box/Box.style';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const steps = [
    {
        keyTrans: 't.general_info',
        keyValidation: 'stepOne',
        keyStep: 0
    },
    {
        keyTrans: 't.network_information',
        keyValidation: 'stepTwo',
        keyStep: 1
    },
    {
        keyTrans: 't.fleet_information',
        keyValidation: 'stepThree',
        keyStep: 2
    },
    {
        keyTrans: 't.tyre_information',
        keyValidation: 'stepFour',
        keyStep: 3
    },
    {
        keyTrans: 't.site_configuration',
        keyValidation: 'stepFive',
        keyStep: 4
    }
];

const SiteSurveyContent: React.FC<SiteSurveyViewProps> = ({
    loadingData,
    publicMode,
    bottonHiddenUpdate,
    modalReturnToProgress,
    loadingReturnToProgress,
    handleSubmit,
    handleNext,
    handleBack,
    updateStep,
    updateSurveyTemporal,
    setModalReturnToProgress,
    handleReturnToPogress
}) => {
    const { t: translate } = useTranslation();
    const activeStep = useRecoilValue(SurveyStepState);
    const surveyFieldRequires = useRecoilValue(SurveyFieldRequiresState);

    if (loadingData) return <SiteSurveyLoadingContainer><Loading /></SiteSurveyLoadingContainer>;

    return (
        <SiteSurveyContainer>
            <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                {steps.map((step, index) => (
                    <Step key={`${index}-${step.keyStep}`}>
                        <StepButton
                            sx={!surveyFieldRequires[step.keyValidation].validStep ? {
                                '& .MuiStepLabel-root .MuiStepLabel-label': {
                                    color: '#d32f2f !important'
                                },
                                '& .MuiStepIcon-root': {
                                    color: '#d32f2f !important'
                                }
                            } : {}}
                            onClick={() => updateStep(step.keyStep)}
                        >
                            {translate(step.keyTrans)}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            {activeStep === 0 && (
                <StepOne
                    activeStep={activeStep}
                    publicMode={publicMode}
                    bottonHiddenUpdate={bottonHiddenUpdate}
                    updateSurveyTemporal={updateSurveyTemporal}
                    handleSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    setModalReturnToProgress={setModalReturnToProgress}
                />
            )}

            {activeStep === 1 && (
                <StepTwo
                    activeStep={activeStep}
                    publicMode={publicMode}
                    bottonHiddenUpdate={bottonHiddenUpdate}
                    updateSurveyTemporal={updateSurveyTemporal}
                    handleSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    setModalReturnToProgress={setModalReturnToProgress}
                />
            )}

            {activeStep === 2 && (
                <StepThree
                    activeStep={activeStep}
                    publicMode={publicMode}
                    bottonHiddenUpdate={bottonHiddenUpdate}
                    updateSurveyTemporal={updateSurveyTemporal}
                    handleSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    setModalReturnToProgress={setModalReturnToProgress}
                />
            )}

            {activeStep === 3 && (
                <StepFour
                    activeStep={activeStep}
                    publicMode={publicMode}
                    bottonHiddenUpdate={bottonHiddenUpdate}
                    updateSurveyTemporal={updateSurveyTemporal}
                    handleSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    setModalReturnToProgress={setModalReturnToProgress}
                />
            )}

            {activeStep === 4 && (
                <StepFive
                    activeStep={activeStep}
                    publicMode={publicMode}
                    bottonHiddenUpdate={bottonHiddenUpdate}
                    updateSurveyTemporal={updateSurveyTemporal}
                    handleSubmit={handleSubmit}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    setModalReturnToProgress={setModalReturnToProgress}
                />
            )}

            {!publicMode && (
                <Dialog
                    fullScreen={false}
                    fullWidth={true}
                    maxWidth='sm'
                    open={modalReturnToProgress}
                    onClose={() => setModalReturnToProgress(false)}
                    aria-labelledby='responsive-dialog-title'
                    className='static_action_buttons'
                >
                    <UiDialogTitle>
                        {translate('t.confirmation')}
                        <ButtonCloseDialog>
                            <ButtonFab
                                size='small'
                                aria-label='add'
                                onClick={() => {
                                    setModalReturnToProgress(false);
                                }}
                            >
                                <Close />
                            </ButtonFab>
                        </ButtonCloseDialog>
                    </UiDialogTitle>

                    <DialogContent sx={{ mt: 2 }} >
                        <Typography sx={{ mb: 2, textAlign: 'center' }}>
                            {translate('p.survey_confirmation')
                        }</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1 }}>
                            <LoadingButton
                                onClick={() => setModalReturnToProgress(false)}
                                disabled={loadingReturnToProgress}
                                variant='contained'
                                color='secondary'
                                size='small'
                                sx={{ mr: 1 }}
                            >
                                {translate('t.cancel')}
                            </LoadingButton>
                            <LoadingButton
                                onClick={() => handleReturnToPogress()}
                                disabled={loadingReturnToProgress}
                                sx={{ mr: 1 }}
                                variant='contained'
                                color='primary'
                                size='small'
                            >
                                {translate('t.confirm')}
                            </LoadingButton>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </SiteSurveyContainer>
    );
};

export default SiteSurveyContent;