import React from 'react';
import { SiteSurveyStepFourForm, StepFourViewProps } from './StepFour.type';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { ListItemCustom, UiScrollbarsSiteSurvey } from '../SiteSurvey.style';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { AutocompleteElement, InputElement } from '../../FormElements';
import { SurveyFieldRequiresState } from '../../../states/component/Survey';
import { useTranslation } from 'react-i18next';
import ButtonsActionSiteSurvey from '../ButtonsActionSiteSurvey/ButtonsActionSiteSurvey';
// import module

export const StepFourContent: React.FC<StepFourViewProps> = ({
    valuesInitForm,
    activeStep,
    optionsSelect,
    publicMode,
    bottonHiddenUpdate,
    handleBack,
    handleNext,
    handleSubmit,
    updateSurveyTemporal,
    setModalReturnToProgress
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const surveyFieldRequires = useRecoilValue(SurveyFieldRequiresState);

    const validationSchema = Yup.object().shape({
        bridgestone: Yup.number().nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            })
            .test('is-zero-or-positive', translate('p.positive_number') as string, (value) => {
                if (value === null || value === undefined) return true;
                return value >= 0;
            }),
        michelin: Yup.number().nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            })
            .test('is-zero-or-positive', translate('p.positive_number') as string, (value) => {
                if (value === null || value === undefined) return true;
                return value >= 0;
            }),
        goodyear: Yup.number().nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            })
            .test('is-zero-or-positive', translate('p.positive_number') as string, (value) => {
                if (value === null || value === undefined) return true;
                return value >= 0;
            }),
        otherBranch: Yup.number().nullable()
            .transform((value, originalValue) => {
                if (originalValue === '') return null;
                return value;
            })
            .test('is-zero-or-positive', translate('p.positive_number') as string, (value) => {
                if (value === null || value === undefined) return true;
                return value >= 0;
            }),
        tyreAdditive: Yup.string(),
        tyreAdditiveOther: Yup.string(),
        fullName: Yup.string(),
        jobTitle: Yup.string(),
        company: Yup.string(),
        emailPersonal: Yup.string().email(translate('t.invalid_email') ?? '')
    });

    const initialErrors = Object.keys(surveyFieldRequires.stepFour.fileds).reduce((acc, key) => {
        if (!surveyFieldRequires.stepFour.fileds[key].valid && !valuesInitForm[key]) {
            acc[key] = translate('p.this_field_is_required');
        }
        return acc;
    }, {});

    return (
        <Formik
            initialValues={valuesInitForm}
            initialErrors={initialErrors}
            onSubmit={(values: SiteSurveyStepFourForm, actions) => {
                handleSubmit({
                    ...values,
                    tyreAdditive: values.tyreAdditive == 'other' ? values.tyreAdditiveOther : values.tyreAdditive
                }, 4, actions.resetForm, actions.setSubmitting);
            }}
            validationSchema={validationSchema}
            validate={() => {
                return initialErrors;
            }}
            validateOnBlur={true}
        >
            {(propsF: FormikProps<SiteSurveyStepFourForm>) => {
                const {
                    touched,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                    submitForm,
                    resetForm,
                    setSubmitting
                } = propsF;
                Object.keys(touched).forEach((key) => {
                    try {
                        validationSchema.validateSyncAt(key, values);
                        if (values[key]) {
                            if(key === 'tyreAdditive' || key === 'tyreAdditiveOther') {
                                if(values['tyreAdditive'] === 'other' && values['tyreAdditiveOther'] === '' ) {
                                    delete errors['tyreAdditive'];
                                    if(!surveyFieldRequires.stepFour.fileds['tyreAdditive'].valid) {
                                        errors['tyreAdditiveOther'] = translate('p.this_field_is_required') ?? '';
                                    }
                                }

                                if(values['tyreAdditive'] === 'other' && values['tyreAdditiveOther'] !== '' ) {
                                    delete errors['tyreAdditive'];
                                    delete errors['tyreAdditiveOther'];
                                }

                                if(values['tyreAdditive'] !== 'other' && values['tyreAdditiveOther'] !== '') {
                                    delete errors['tyreAdditive'];
                                    delete errors['tyreAdditiveOther'];
                                }
                            }else{
                                delete errors[key];
                            }
                        }
                    } catch (validationError: any) {
                        errors[key] = validationError.errors[0];
                    }
                });
                return (
                    <>
                        <UiScrollbarsSiteSurvey
                            autoHeight
                            renderThumbVertical={({ style, ...props }) => (
                                <div {...props} className='scrollYcustom' style={{ ...style }} />
                            )}
                        >
                            <Grid container spacing={2}>
                                <Grid item md={5} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{translate('t.tyre_information')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container paddingLeft='10px'>
                                        <Grid item sm={12} md={7} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.manufacturer_split')} (%)</strong>
                                        </Grid>
                                        <Grid item sm={12} md={5}>
                                            <InputElement
                                                label='Bridgestone'
                                                name='bridgestone'
                                                value={values.bridgestone}
                                                error={errors.bridgestone}
                                                touched={touched.bridgestone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type='number'
                                                required={true}
                                                $noMinWidth
                                                xl={12}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                            <InputElement
                                                label='Michelin'
                                                name='michelin'
                                                value={values.michelin}
                                                error={errors.michelin}
                                                touched={touched.michelin}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type='number'
                                                required={true}
                                                $noMinWidth
                                                xl={12}
                                                fullWidth
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                            <InputElement
                                                label='Goodyear'
                                                name='goodyear'
                                                value={values.goodyear}
                                                error={errors.goodyear}
                                                touched={touched.goodyear}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type='number'
                                                required={true}
                                                fullWidth
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                            <InputElement
                                                label='Other'
                                                name='otherBranch'
                                                value={values.otherBranch}
                                                error={errors.otherBranch}
                                                touched={touched.otherBranch}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type='number'
                                                required={true}
                                                fullWidth
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={7}><Divider /></Grid>
                                    </Grid>

                                    <Grid container paddingLeft='10px'>
                                        <Grid item sm={12} md={7} sx={{ paddingTop: '20px' }} >
                                            <strong>{translate('p.tyre_additive_used')}</strong>
                                        </Grid>
                                        <Grid item sm={12} md={5}>
                                            <AutocompleteElement
                                                label=''
                                                name='tyreAdditive'
                                                size='small'
                                                options={optionsSelect ?? []}
                                                value={values.tyreAdditive}
                                                defaultValue={optionsSelect.find(
                                                    option => option.value === values.tyreAdditive
                                                )}
                                                error={errors.tyreAdditive}
                                                touched={touched.tyreAdditive}
                                                onBlur={handleBlur}
                                                setFieldValue={setFieldValue}
                                                optionsPropertyLabel='label'
                                                optionsPropertyValue='value'
                                                required
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                            <InputElement
                                                label={translate('t.other')}
                                                name='tyreAdditiveOther'
                                                value={values.tyreAdditiveOther}
                                                error={errors.tyreAdditiveOther}
                                                touched={touched.tyreAdditiveOther}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={values.tyreAdditive !== 'other'}
                                                required={false}
                                                fullWidth
                                                $noMinWidth
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={7}><Divider /></Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={7} sx={{ padding: '10px 20px' }} >
                                    <ListItemCustom
                                        isActive={true}
                                        disableTouchRipple
                                        disableRipple
                                    >
                                        <Typography variant='h5' gutterBottom>
                                            <strong>{translate('t.tire_service_contacts')}</strong>
                                        </Typography>
                                    </ListItemCustom>
                                    <Grid container>
                                        <InputElement
                                            label={translate('t.full_name')}
                                            name='fullName'
                                            value={values.fullName}
                                            error={errors.fullName}
                                            touched={touched.fullName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            fullWidth
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        />
                                        <InputElement
                                            label={translate('t.job_title')}
                                            name='jobTitle'
                                            value={values.jobTitle}
                                            error={errors.jobTitle}
                                            touched={touched.jobTitle}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            fullWidth
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        />
                                        <InputElement
                                            label={translate('t.company')}
                                            name='company'
                                            value={values.company}
                                            error={errors.company}
                                            touched={touched.company}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            fullWidth
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        />
                                        <InputElement
                                            label={translate('t.email')}
                                            name='emailPersonal'
                                            value={values.emailPersonal}
                                            error={errors.emailPersonal}
                                            touched={touched.emailPersonal}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            required={true}
                                            fullWidth
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </UiScrollbarsSiteSurvey>
                        <ButtonsActionSiteSurvey
                            activeStep={activeStep}
                            values={{
                                ...values,
                                tyreAdditive: values.tyreAdditive == 'other' 
                                    ? values.tyreAdditiveOther 
                                    : values.tyreAdditive
                            }}
                            publicMode={publicMode}
                            isSubmitting={isSubmitting}
                            errors={Object.keys(errors).length > 0}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            submitForm={submitForm}
                            handleSubmit={handleSubmit}
                            resetForm={resetForm}
                            setSubmitting={setSubmitting}
                            setModalReturnToProgress={setModalReturnToProgress}
                        />
                        <Button
                            onClick={() => updateSurveyTemporal({
                                ...values,
                                tyreAdditive: values.tyreAdditive == 'other' 
                                    ? values.tyreAdditiveOther 
                                    : values.tyreAdditive
                            })}
                            sx={{ display: 'none' }}
                            variant='contained'
                            size='small'
                            ref={bottonHiddenUpdate}
                        >
                        </Button>
                    </>
                );
            }}
        </Formik>
    );
};